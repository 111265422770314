import {PersonSex} from "@/constants/PersonConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

export default class AddGreenCardPayload {
    personId!: number;
    surname!: string;
    givenName!: string;
    uscisCode!: string;
    category!: string;
    number!: string;
    countryOfBirth!: string;
    dateOfBirth!: Date;
    sex: PersonSex | null = null;
    residentSince!: Date;
    cardExpires!: Date;
    front!: FileMetaDTO;
    back!: FileMetaDTO;

    constructor(init?: Partial<AddGreenCardPayload>) {
        Object.assign(this, init);
    }
}